import React, { FunctionComponent } from 'react'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareSideCardCustomType {
  contentType: 'EasySoftwareSideCardCustom'
  displayText: string
  contentItemId: string
  sideCardCustomButton: PuxButtonType
  sideCardCustomText: PuxWysiwygHtmlBodyType
}

import './EasySoftwareSideCard.scss'

const rootClass = `sidebar`

const EasySoftwareSideCardCustom: FunctionComponent<EasySoftwareSideCardCustomType> = (
  props
) => {
  return (
    <div className={`${rootClass}-box`}>
      <h4 className={`${rootClass}-box-title`}>{props.displayText}</h4>
      <PuxWysiwyg
        className={`${rootClass}-box-perex perex`}
        content={props.sideCardCustomText}
      />
      <PuxButton {...props.sideCardCustomButton} />
    </div>
  )
}

export default EasySoftwareSideCardCustom
